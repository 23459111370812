import {InputTextHandler} from "../Panel/common/InputTextHandler";
import {fields as schema} from "../Panel/tabs/Schedule/components/LoadedScheduleHook";

export default function getModelHandlers({state,setState,localeLanguage}){

    var schema = require('../Panel/tabs/Schedule/components/LoadedScheduleHook').fields
    let handlers = {};
    var field_type = 1;
    var field_name = 0;
    var field_string = 3;
    var field_title = 4;

    const {
        onTextchange
        ,handleDraggableListItemInState,handleDraggableOrder
        ,  handleEditor
        ,  handleDateTimePicker
    }
        = InputTextHandler({state: state, setState: setState, lang: localeLanguage});

    schema.map((field,index) => {
        const name_array = field[field_name].split("_")
        let name_part = "";

        name_array.map((string,index) => {
            name_part = name_part + string.charAt(0).toUpperCase() + string.slice(1)
        })
        let const_name = "handle"+name_part+"Change"

        if(field[field_type] == "text"){
            handlers[const_name] = (event) => {onTextchange(event)};
        }else if(field[field_type] == "editor") {
            handlers[const_name] = (event) => {handleEditor(event,field[field_name])};
        }else if(field[field_type] == "datetimer") {
            handlers[const_name] = (event) => {handleDateTimePicker(event,field[field_name])};
        }else if(field[field_type] == "autocomplete_listDrag") {
            let const_name_list = "handle"+name_part+"ListChange"
            handlers[const_name_list] = (event,values) => {handleDraggableListItemInState(event,values,field[field_name])};
            handlers[const_name] = (event) => {handleDraggableOrder(event,field[field_name])};
        }
        schema[index][field_title] = const_name;
    })

    // const UIFields = GetFieldsUi({schema:schema,handlers:handlers,data:props})

    return handlers

}