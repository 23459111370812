import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import PageProvider from "../../../Page/PageContext";
import * as constants from "../../common/Constants";
import {InputTextHandler} from "../../common/InputTextHandler";
import {SetLocaleData} from "../../../common/SetLocaleData";
import {FormErrors} from "../../../common/FormErrors";
import ToastProvider from "../../../../Providers/ToastProvider"
import {makeStyles} from "@material-ui/core/styles";
import Users from "../../../../api/services/Panel/users";

// import Users from "../../../../../api/services/Panel/users"
// import Agenda from "../../../../../api/services/Panel/agenda"

// const useedStyles = makeStyles({
//     root: {
//         color: "#39BB0F",
//         "&$checked": {
//             color: "#39BB0F"
//         }
//     }
// });

const UsersHook = (props) => {

    const {settings} = useContext(PageProvider);
    const {notify} = useContext(ToastProvider);
    const {t, i18n} = useTranslation();
    // let non_translate = ["executives","socials"]
    const {hasError, errorMessage, setFormErrors} = FormErrors();
    const [validationErrors, setValidationErrors] = useState(null);
    const [error, setError] = useState([]);
    // const [editorCV, setEditorCV] = useState(null);
    const [user, setUser] = useState([]);
    // const [countries, setCountries] = useState([]);
    // const [timezones, setTimezones] = useState([]);
    const [optionsState, setOptionsState] = useState([
        {key: 'Coordinator', title: t('Coordinator'), id: 2},
        // {title: t('Attendee'), id: 3},
        {key: 'Speaker', title: t('Speaker'), id: 4},
        {key: 'Executive', title: t('Executive'), id: 5},
        // {key: 'Hidden', title: t('Hidden'), id: 7},
        {key: 'Administrator', title: t('Administrator'), id: 6},
    ]);
    const [loading, setLoading] = useState(true);



    const {
        handleCheckbox,
        handleCustomValue,
        onTextchange
        , handleDraggableListItemInState
        , handleDraggableOrder
        , handleEditor
        ,handleTwoDimensionArray
        ,setImage
    }
        = InputTextHandler({state: user, setState: setUser});
        // = InputTextHandler({state: user, setState: setUser, lang: localeLanguage});
    // const {setLocaleDataFunction} = SetLocaleData(non_translate);
    let correct_end_time = {end_time: [t('set_correct_end_time')]}

    useEffect(() => {
        setFormErrors(validationErrors);
    }, [validationErrors])

    // const setLocaleData = (data) => {
    //     setLocaleDataFunction(data ? data : user, setUserLocale, localeLanguage, setEditorDescription);
    // }
    //
    // useEffect(() => {
    //     setLocaleData();
    // }, [user, localeLanguage])

    // useEffect(() => {
    //     if (Object.keys(user).length > 0) {
    //         setEditorCV(user.description[localeLanguage]);
    //     }
    // }, [localeLanguage])

    // const changeLocale = (lang) => {
    //     setLocaleLanguage(lang);
    // }

    useEffect(() => {


    },[])

    useEffect(() => {
        if (props.id) {
            loadUser();
        }
        // else{
        //     loadSponsorNew();
        // }
        // Call the function
    }, []);

    const loadSponsorNew = () => {
        

    }

    const loadUser = async () => {
        Users.get(props.id).then((response) => {
            setUser(response[0]);
            setLoading(false);
        });
    }

    const create = async () => {

        const formData = new FormData();
        Object.keys(user).map((value,index)=>{
            if( typeof user[value] === 'object' && value != "file"){
                formData.append(value, JSON.stringify(user[value]));
            }else{
                formData.append(value, user[value]);
            }
        })


        // console.log("formData",formData)
        Users.create(formData).then((response) => {
            if(response.status == "error"){
                setError(response.data.errors);
            }else{
                setError([]);


                notify(t('User_created'));
                props.triggerdataload(response);
                props.triggerdrawerfunc(false);
            }
        });

    }

    const update = async () => {
        Users.update(user).then((response) => {

            if (response.status == "error") {
                setError(response.data.errors);
            } else {
                setError([]);

                notify(t('User_updated'));

                let index_id;
                const i = props.posts.map((single_post, index) => {
                        if (single_post.id === response.data.id) {
                            index_id = index;
                            return true;
                        }
                    }
                );

                let items = [...props.posts];
                let item = {...items[index_id]};

                item.name = response.data.name;
                item.email = response.data.email;
                item.roles = response.data.roles;
                items[index_id] = item;
                // console.log("sss");
                // console.log(items);
                props.triggerdataload(items);
                props.triggerdrawerfunc(false);

            }
        });
    }

    const onSubmit = () => {
        if (props.id) {
            update();
        } else {
            create();
        }
    }


    // event, state, type, extra (κλειδιά από keys σε τα arrays)
    const onInputchange = (event) => {
        onTextchange(event);
    }

    const handleCV = (data) => {
        handleEditor(data, 'cv');
    }


    const handleUserCheckboxes = (event) => {
        event.persist();
        handleCheckbox(event)
    }

    const onRolesChange = (event, values) => {
        handleDraggableListItemInState(event, values,'roles')
    }


    const onCountryChange = (event, newValue) => {
        handleCustomValue(newValue,'Country','code');
    };

    const onTimezoneChange = (event, newValue) => {
        handleCustomValue(newValue,'timezone','area');
    };

    const getImage = (file) => {
        setImage(file,"file")
    }


    return {
        error:error,
        user:user,
        optionsState:optionsState,
        settings: settings,
        handleCV,
        handleUserCheckboxes,
        getImage,
        handleCheckbox,
        onRolesChange,
        onCountryChange,
        onTimezoneChange,
        // localeLanguage: localeLanguage,
        loading: loading,
        onInputchange,
        onSubmit,
        hasError, errorMessage, setFormErrors
    };

}

export default UsersHook;