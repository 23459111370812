import React, {useState, useEffect, useContext, createRef} from 'react';
import Users from "../../../api/services/Panel/users";
import PageProvider from "../../Page/PageContext";
import ToastProvider from "../../../Providers/ToastProvider";
// import Schedule from "../schedule";
import {useTranslation} from "react-i18next";
import * as constants from "../../Panel/common/Constants";
import {InputTextHandler} from "../common/InputTextHandler";
import {SetLocaleData} from "../../common/SetLocaleData";
import {FormErrors} from "../../common/FormErrors";
import Handlers from "../../CRUD/setters"


function updateView(WrappedComponent,model) {

    return function (props) {
        // console.log("updateView.WrappedComponent",props);
        let id = props.id?props.id:null;
        let ModelName = model

        let Model = require('../../../api/services/Panel/'+ModelName).default;

        const {settings} = useContext(PageProvider);
        const {t, i18n} = useTranslation();
        // props.id = 1;
        let non_translate = ["syntonistes", "speakers", "start_time_picker", "end_time_picker"]

        const [loading, setLoading] = useState(false);
        const [state, setState] = useState({});
        const [editorSubtitle, setEditorSubtitle] = useState(null);
        const [stateLocale, setStateLocale] = useState({});
        const [localeLanguage, setLocaleLanguage] = useState(i18n.language);
        const [validationErrors, setValidationErrors] = useState(null);
        const {setLocaleDataFunction} = SetLocaleData(non_translate);
        const {hasError, errorMessage, setFormErrors} = FormErrors();

        useEffect(() => {
            if (Object.keys(state).length > 0) {
                setEditorSubtitle(state.subtitle[localeLanguage]);
            }
        }, [localeLanguage])

        const changeLocale = (lang) => {
            setLocaleLanguage(lang);
        }

        let handlers = Handlers({state:state,setState:setState,localeLanguage:localeLanguage});


        useEffect(() => {
            setFormErrors(validationErrors);
        }, [validationErrors])

        const setLocaleData = (data) => {
            // console.log("setLocaleData",state,stateLocale)
            setLocaleDataFunction(data ? data : state, setStateLocale, localeLanguage, setEditorSubtitle);
        }

        useEffect(() => {
            setLocaleData();
            // console.log("changeLocale",state,stateLocale)
        }, [state, localeLanguage])

        // console.log("Been there!!!")
        // console.log(WrappedComponent)

        const loadEdit = async () => {

            Model.get(props.id,"panel").then((response) => {
                // console.log("oooloadEdit",response)
                let start_time = new Date(response.start_time * 1000)
                let end_time = new Date(response.end_time * 1000)

                response.start_time_picker = start_time;
                response.end_time_picker = end_time;
                response.start_time = start_time.getTime();
                response.end_time = end_time.getTime();
                setState(response);
                setLoading(false);
                setEditorSubtitle(response.subtitle[i18n.language]);
            });
            // console.log("loadEdit",Model)

        }

        const loadNew = async () => {
            let stime = new Date(settings.EVENT_DATE_TIMESTAMP);

            var obj_settings = {
                name: "newschedule",
                start_date: stime,
                end_date: stime,
                start_time_picker: stime.getTime(),
                end_time_picker: stime.getTime(),
            };
            console.log("obj_settings",obj_settings);
            setState(obj_settings);

        }

        const create = async () => {

            Model.create(state).then((response) => {
                if (response.status == 'error') {
                    // console.log("data.errors", response);
                    setValidationErrors(response.data.errors);
                } else if (response.status == 'ok') {


                    let items = [...props.posts];
                    let item = {};
                    item.title = response.title;
                    item.startDate = response.startDate;
                    item.startTime = response.startTime;
                    item.id = response.id;
                    items.push(item)

                    // console.log("items", items)
                    props.triggerdataload(items);
                    props.ooo(false);
                }
            });
        }

        const update = async () => {
            // console.log("update",state,stateLocale)
            Model.update(stateLocale,localeLanguage).then((response) => {

                if (response.status == 'error') {
                    // console.log("data.errors", response);
                    setValidationErrors(response.errors);
                }else if(response.status == 'ok'){

                    let items = [...props.posts];
                    let item = {};
                    item.title = response.title;
                    item.startDate = response.startDate;
                    item.startTime = response.startTime;
                    item.id = response.id;
                    items.push(item)

                    // console.log("items", items)
                    props.triggerdataload(items);
                    props.ooo(false);

                }

            });

        }



        const onSubmit = () => {
            if (props.id) {
                update();
            } else {
                create();
            }
        }


        useEffect(() => {
            if (props.id) {
                loadEdit();
            }else{
                loadNew();
            }
        }, [settings]);




        let hooks = {...props,handlers:handlers,create,update,changeLocale,id,loadEdit,state,setState,stateLocale,setStateLocale,localeLanguage,hasError,validationErrors,errorMessage,onSubmit,model:ModelName};

        return  WrappedComponent(hooks)

    }

}

// const Functions = {
//     updateView
// }

export default updateView;