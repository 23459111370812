import React, {useCallback, useContext, useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "@material-ui/core/Button";
import DrawerWindow from "../../common/DrawerWindow";
import EditUser from "./components/EditUser";
import UserCreateButton from "./components/UserCreateButton";
import NewUser from "./components/NewUser";
import * as constants from "../../common/Constants"
import {ReactSVG} from 'react-svg'
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import PageProvider from "../../../Page/PageContext";
import TooltipProvider from "../../../../Providers/TooltipProvider";
import ModalWithButton from "../../../common/ModalWithButton";
import UsersImport from "./components/UsersImport";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import Chip from '@material-ui/core/Chip';
import green from "@material-ui/core/colors/green";
import Typography from "@material-ui/core/Typography";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import IconButton from "@material-ui/core/IconButton";
import Loader from "../../../common/Loader";
import StageMobile from "../../../Page/content/Stage/StageMobile";
import StageIndividual from "../../../Page/content/Stage/StageIndividual";
import {createMedia} from "@artsy/fresnel";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from '@material-ui/icons/Info';


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});
const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})
const chipStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));

const edit_icon = {
    icon: "/images/edit.svg",
    delete: "/images/trash.svg",
}

const rowStyle = ({ data }) => {
    return null;
    // const colorMap = {
    //     invalid: '#ef9a9a'
    // }
    // return {
    //     color: validateRow(data) ? null : colorMap["invalid"]
    // }
}
const filterValue = [
    { name: 'name', operator: 'contains', type: 'string', value: '' },
    { name: 'email', operator: 'contains', type: 'string', value: '' },
];

const gridStyle = { minHeight: 530,borderRadius:"20px",borderColor:"#fff", boxShadow:"0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"}
const rowHeights = [
    { label: 'large', value: 60 }
]

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

export default function Users() {

    const {t, i18n} = useTranslation();
    const {settings,setReloadData} = useContext(PageProvider);
    const {BoldTooltip} = useContext(TooltipProvider);
    const [gridRef, setGridRef] = useState(null);
    const [tooltipState, setTooltipState] = useState({});

    const [drawerstate, setDrawerstate] = useState({
            open: false,
            id: null
        }
    );
    const [optionsState, setOptionsState] = useState([
        {key:'Coordinator',title: t('Coordinator'), id: 2},
        // {title: t('Attendee'), id: 3},
        {key:'Speaker',title: t('Speaker'), id: 4},
        {key:'Executive',title: t('Executive'), id: 5},
        {key:'Administrator',title: t('Administrator'), id: 6},
        // {key:'Hidden',title: t('Hidden'), id: 7},
    ]);

    const chipclasses = chipStyles();


    useEffect(() => {
        // Call the function

        if(settings.ADMIN_THRESHOLD === true){
            let roles_states = [...optionsState];
            roles_states.splice(5,1)
            setOptionsState(roles_states)
            // console.log("a")
        }
        if(settings.ADMIN_THRESHOLD == false ){
            // console.log(optionsState.length)
            // console.log("b")
            setOptionsState((optionsState) => [...optionsState,{title: t('Administrator'), id: 6}])
            // let roles_states = [...optionsState];
            // roles_states.splice(5,1)
            // setOptionsState(roles_states)
        }
    }, [settings]);

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    const loadItems = async () => {

        // Till the data is fetch using API
        // the Loading page will show.
        setLoading(true);

        // Await make wait until that
        // promise settles and return its reult
        const response = await axios.post(
            constants.LocalUrl + "/data/get_all_users");

        // After fetching data stored it in posts state.
        setUsers(response.data);

        // Closed the loading page
        setLoading(false);
    }

    useEffect(() => {
        // Call the function
        loadItems();
    }, []);


    const notify = () => toast.success(t('Roles changed!'), {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const toggleChildMenu = (attached, sponsor_id) => () => {
        setDrawerstate({...drawerstate, open: attached, id: sponsor_id});
    };

    const toggleChildMenuFunc = (attached, sponsor_id) => {
        setDrawerstate({...drawerstate, open: attached, id: sponsor_id});
    };

    const newPostsHandler = (data) => {
        // setUsers((posts) => [data.data, ...users]);
        loadItems();
    };

    const updatePostsHandler = (data) => {
        // setUsers((posts) => data);
        loadItems();
    };

    const resendOnboardingMail = (user_id) => {
        axios.post(constants.LocalUrl + '/data/resendOnboardingMail', {user_id: user_id}).then((response) => {
            let options = {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            };
            if (response.data.status == 'error') {
                toast.error(t('onboarding_mail_not_resent'), options);
            }
            else if (response.data.status == 'ok') {
                toast.success(t('onboarding_mail_resent'), options);
            }
        })
    };

    let columns = [
        { name: 'approved_at', header: t('Status'), maxWidth: 90, defaultFlex: 1, draggable: false,
            render: (value)=> {
                return (
                    value.value
                        ? <CheckIcon style={{color:"green"}}/>
                        : <>
                            <CloseIcon style={{color:"red"}}/>
                            <BoldTooltip title={t("onboarding_mail_send_button_text")} arrow>
                            <IconButton color="#000000" component="span" onClick={() => resendOnboardingMail(value.data.id)}>
                                <MailOutlineIcon />
                            </IconButton>
                            </BoldTooltip>
                        </>
                )
            }
        },
        { name: 'name', header: t('Fullname'), minWidth: 50, draggable: false, defaultFlex: 2 },
        { name: 'email', header: t('E-mail'), minWidth: 50, draggable: false, defaultFlex: 2 },
        // { name: 'id', header: 'Roles', minWidth: 50, draggable: false, defaultFlex: 2,
        // render:(value)=>{return(
        //     <div>
        //         {console.log(value)}
        //         <Autocomplete
        //             multiple
        //             id="tags-standard"
        //             options={optionsState}
        //             onChange={(e, val) => onTagsChange(e, val, value.data.id)}
        //             disableCloseOnSelect
        //             getOptionLabel={(option) => option.title}
        //             defaultValue={return_options(value.data.roles)}
        //             renderOption={(option, {selected}) => (
        //                 <React.Fragment>
        //                     <Checkbox
        //                         icon={icon}
        //                         checkedIcon={checkedIcon}
        //                         style={{marginRight: 8}}
        //                         checked={selected}
        //                         // onClick={changeUserRole(option.title,row.id)}
        //
        //                     />
        //                     {option.title}
        //                 </React.Fragment>
        //             )}
        //
        //             renderInput={(params) => (
        //                 <TextField {...params} variant="standard" label={t("Roles")}
        //                            placeholder={t("Choose a role...")}/>
        //             )}
        //         />
        //     </div>
        // )}},
        { name: 'roles', header: t('Roles'), minWidth: 50, draggable: false, defaultFlex: 2,
        render:(value)=>{return(

            <div className={chipclasses.root}>
                {return_options(value.data.roles).map((data) => {
                    return (

                        <li key={data.key}>
                            <Chip
                                label={data.title}
                                className={chipclasses.chip}
                            />
                        </li>

                    );
                })}
            </div>
        )}},
        { name: 'id', header: ' ', minWidth: 50, defaultFlex: 1,
            render: ({value})=> {return(

                <div style={{textAlign:"right"}}>
                    <BoldTooltip title={t("Edit")} arrow>
                    <Button className="edit-button" size="medium"
                            // style={{marginBottom:"5px"}}
                            onClick={toggleChildMenu(true, value)}
                            sponsor-id={value} height="50" color="secondary"
                    >
                        <ReactSVG beforeInjection={(svg) => {
                            svg.classList.add('svg-edit-button');
                            svg.setAttribute('style', 'width:29px');
                        }} src={constants.LocalUrl + edit_icon.icon}/></Button></BoldTooltip>
                    {value != settings.setup_object.support_uid && value != settings.setup_object.root_uid
                        ? <BoldTooltip title={t("Delete")} arrow><Button className="delete-button" size="medium"
                            onClick={()=>deleteRow(value)} height="50" color="secondary">
                            <ReactSVG beforeInjection={(svg) => {
                                svg.classList.add('svg-edit-button');
                                svg.setAttribute('style', 'width:29px');
                            }} src={constants.LocalUrl + edit_icon.delete}/>
                        </Button></BoldTooltip>
                        : null}
                </div>
            )}},

    ];


    let columns_mobile = [
        { name: 'approved_at', header: t('Status'), maxWidth: 90, defaultFlex: 1, draggable: false,
            render: (value)=> {
                return (
                    value.value
                        ? <CheckIcon style={{color:"green"}}/>
                        : <>
                            <CloseIcon style={{color:"red"}}/>
                            <BoldTooltip title={t("onboarding_mail_send_button_text")} arrow>
                                <IconButton color="#000000" component="span" onClick={() => resendOnboardingMail(value.data.id)}>
                                    <MailOutlineIcon />
                                </IconButton>
                            </BoldTooltip>
                        </>
                )
            }
        },
        { name: 'name', header: t('Fullname'), minWidth: 50, draggable: false, defaultFlex: 1,render: ( value ) => {return(
                <div>
                    <span>{value.data.name}</span>
                    {/*{console.log(tooltipState[value.data.id],tooltipState)}*/}
                    {/*{console.log("tooltipState",tooltipState[value.data.id])}*/}
                    <BoldTooltip open={tooltipState[value.data.id]?true:false}  onClick={handleTooltipState(value.data.id)} title={tooltip_layout(value)} arrow>
                        <InfoIcon className={tooltipState[value.data.id]?"info_user_panel active":"info_user_panel not"}/>
                    </BoldTooltip>
                </div>
            )}},
        // { name: 'email', header: t('E-mail'), minWidth: 50, draggable: false, defaultFlex: 2 },
        { name: 'id', header: ' ', minWidth: 50, defaultFlex: 1,
            render: ({ value })=> {return(

                <div style={{textAlign:"right"}}>
                    <BoldTooltip title={t("Edit")} arrow>
                        <Button className="edit-button" size="medium"
                            // style={{marginBottom:"5px"}}
                                onClick={toggleChildMenu(true, value)}
                                sponsor-id={value} height="50" color="secondary"
                        >
                            <ReactSVG beforeInjection={(svg) => {
                                svg.classList.add('svg-edit-button');
                                svg.setAttribute('style', 'width:29px');
                            }} src={constants.LocalUrl + edit_icon.icon}/></Button></BoldTooltip>
                    {value != settings.setup_object.support_uid && value != settings.setup_object.root_uid
                        ? <BoldTooltip title={t("Delete")} arrow><Button className="delete-button" size="medium"
                            // style={{marginBottom:"5px"}}
                                                                         onClick={()=>deleteRow(value)} height="50" color="secondary">
                            {/*<DeleteIcon></DeleteIcon>*/}
                            <ReactSVG beforeInjection={(svg) => {
                                svg.classList.add('svg-edit-button');
                                svg.setAttribute('style', 'width:29px');
                            }} src={constants.LocalUrl + edit_icon.delete}/>
                        </Button></BoldTooltip>
                        : null}
                </div>
            )}},

    ];

    const handleTooltipState = (id) => () => {
        let t_state = {...tooltipState}
        Object.keys(t_state).map((key) => {
            if(key != id){
                t_state[key] = false;
            }
        })
        // console.log("tooltipState.length",tooltipState,t_state,t_state.length,tooltipState.length,id)
        t_state[id] = !t_state[id]
        // console.log("t_state",t_state,t_state[id],id,Object.keys(t_state).length)
        setTooltipState(t_state);
    };

    const changeUserRole = (roles, user_id) => () => {
        // console.log(roles, user_id);
        axios.post(constants.LocalUrl + '/data/upddate_user_role', {roles, user_id}).then((response) => {
            // console.log("gone")
            setReloadData(true)
            // this.state.sponsors = response.data;
        })

    };

    const onTagsChange = (event, values, user_id) => {
        let roled = [];
        values.map((value) => {
            roled.push(value.id)
        })

        axios.post(constants.LocalUrl + '/data/upddate_user_role', {roled, user_id}).then((response) => {
            // this.state.sponsors = response.data;
            setReloadData(true)
            notify();
        })
        // console.log(event, values, user_id, roled);
    }

    if (loading === true || Object.keys(users).length === 0 || Object.keys(settings).length === 0) {
        return <Loader/>
    }


    function return_options(roles) {

        let arr = [];
        optionsState.map((e, index) => {
            if (roles && roles.includes(e.id)) {
                arr.push(optionsState[index]);
            }
        });

        return arr;

    }

    const deleteRow = (id) => {
        console.log("deleteRow",id)
        if (window.confirm(t('user_delete_confirm_text'))) {
            const response = axios.post(constants.LocalUrl + "/data/delete_object", {object: 'BackpackUser',id:id}).then(response => {
                if(response.data.status == "error"){
                    toast.error(t('item not deleted'), window.toastOptions);
                }else{
                    toast.success(t('item deleted'), window.toastOptions);
                }
                updatePostsHandler(null);
            });
        }
    }

    function tooltip_layout (value) {

            return(
                <div>
                    <Grid container spacing={3} style={{width:"100%",padding:'2px', overflowY:'auto', margin:"0"}}>
                        <Grid item xs={12} style={{display:"flex",paddingRight:"0"}}>
                                <Typography xs={12} variant="h6" style={{textAlign:'left'}} gutterBottom>
                                    {value.data.name}
                                </Typography>
                            <hr style={{height: '1px', margin: 0, color: 'rgb(57, 187, 15)'}} />
                        </Grid>

                        <Grid item xs={6} style={{textAlign:'left'}}>
                            <Typography xs={6} variant="h7" style={{textAlign:'left'}} gutterBottom>
                                {t('Roles')}
                                <div className={chipclasses.root}>
                                    {return_options(value.data.roles).map((data) => {
                                        return (
                                            <li key={data.key}>
                                                <Chip
                                                    label={data.title}
                                                    className={chipclasses.chip}
                                                />
                                            </li>
                                        );
                                    })}
                                </div>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} style={{textAlign:'left'}}>
                            <Typography xs={6} variant="h7" style={{textAlign:'left'}} gutterBottom>
                                {t('Email')}
                            </Typography>
                            <div>
                                {value.data.email}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            )

    }



    return (
        <SimpleBar forceVisible="y" autoHide={false} style={{maxHeight:"658px",height:"658px"}}>
        <div style={{width:"100%"}}>
        <Grid container spacing={3} style={{width:"100%",padding:'2px', overflowY:'auto', margin:"0"}}>
            <Grid item xs={12} style={{display:"flex",paddingLeft:"0",paddingRight:"0"}}>
            <UserCreateButton type={'small'} onClick={toggleChildMenu(true)}/><br/><br/>
            &nbsp; &nbsp; &nbsp;
            <ModalWithButton button_text={t('Import Users')} triggerdataload={newPostsHandler}>
                <UsersImport style={{marginLeft:"15px"}} triggerdataload={newPostsHandler} ></UsersImport>
            </ModalWithButton>
            </Grid>
            <Grid className="panel_user_table" item xs={12}>
                <MediaContextProvider>

                    <Media at='mobile'>
                        <ReactDataGrid
                            theme='green-light'
                            onReady={setGridRef}
                            idProperty="id"
                            showCellBorders='horizontal'
                            // renderColumnContextMenu={renderColumnContextMenu}
                            columns={columns_mobile}
                            rowHeight='auto'
                            dataSource={users??[]}
                            style={gridStyle}
                            nativeScroll={true}
                            rowStyle={rowStyle}
                            defaultFilterValue={filterValue}
                            pagination defaultLimit={10}
                            enableKeyboardNavigation={false}
                        />
                    </Media>

                    <Media at='computer'>
                        <ReactDataGrid
                            theme='green-light'
                            onReady={setGridRef}
                            idProperty="id"
                            showCellBorders='horizontal'
                            // renderColumnContextMenu={renderColumnContextMenu}
                            columns={columns}
                            rowHeight='auto'
                            dataSource={users??[]}
                            style={gridStyle}
                            nativeScroll={true}
                            rowStyle={rowStyle}
                            defaultFilterValue={filterValue}
                            pagination defaultLimit={10}
                            enableKeyboardNavigation={false}
                        />
                    </Media>

                </MediaContextProvider>

            </Grid>

            <DrawerWindow open={drawerstate.open}>
                {drawerstate.id ? <EditUser id={drawerstate.id} posts={users} triggerdrawer={toggleChildMenu}
                                            triggerdrawerfunc={toggleChildMenuFunc}
                                            triggerdataload={updatePostsHandler}/> :
                    <NewUser triggerdrawer={toggleChildMenu} triggerdrawerfunc={toggleChildMenuFunc}
                             triggerdataload={newPostsHandler}/>}
            </DrawerWindow>
        </Grid>
        </div>
        </SimpleBar>
    )


}

