import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import PageProvider from "../../../../Page/PageContext";
import * as constants from "../../../common/Constants";
import {InputTextHandler} from "../../../common/InputTextHandler";
import {SetLocaleData} from "../../../../common/SetLocaleData";
import {FormErrors} from "../../../../common/FormErrors";
import Users from "../../../../../api/services/Panel/users"
import Agenda from "../../../../../api/services/Panel/agenda"


function LoadedScheduleHook(props) {
    // console.log("LoadedScheduleHook",props);
    const {settings} = useContext(PageProvider);
    const {t, i18n} = useTranslation();
    let non_translate = ["syntonistes", "speakers", "start_time_picker", "end_time_picker"]
    const [speakers,setSpeakers] = useState([])
    const [coordinators,setCoordinators] = useState([])


    const {
        onTextchange
        , handleDraggableListItemInState
        , handleDraggableOrder
        , handleEditor
        , handleDateTimePicker
    }
        = InputTextHandler({state: props.state, setState: props.setState, lang: props.localeLanguage});
    // const {setLocaleDataFunction} = SetLocaleData(non_translate);
    let correct_end_time = {end_time: [t('set_correct_end_time')]}


    useEffect(() => {

        if (!props.id) {

            let stime = new Date(settings.EVENT_DATE_TIMESTAMP);

            var obj_settings = {
                name: "newschedule",
                start_date: stime,
                end_date: stime,
                start_time_picker: stime.getTime(),
                end_time_picker: stime.getTime(),
            };
            console.log("obj_settings",obj_settings);
            props.setState(obj_settings);
        }

        Users.speakers(props.localeLanguage).then((response) => {
            setSpeakers(response)
        });

        Users.coordinators(props.localeLanguage).then((response) => {
            setCoordinators(response)
        });


    }, []);




    // event, state, type, extra (κλειδιά από keys σε τα arrays)
    const onInputchange = (event) => {
        // console.log("onInputchange",event)
        onTextchange(event);
    }

    const handleDescription = (data) => {
        handleEditor(data, 'subtitle');
    }

    const handleSpeakersOrder = (event) => {
        handleDraggableOrder(event, 'speakers');
    }

    const handleSyntonistesOrder = (event) => {
        handleDraggableOrder(event, 'syntonistes');
    }

    const onSyntonistesChange = (event, values) => {
        handleDraggableListItemInState(event, values, 'syntonistes');
    }

    const onSpeakersChange = (event, values) => {
        handleDraggableListItemInState(event, values, 'speakers');
    }

    const handleStartTime = (event) => {

        let start_date_now = new Date(event);
        let end_date_now = new Date(props.state.end_time_picker);

        // console.log("start_date_now",start_date_now,end_date_now,end_date_now.getTime(),event.getTime())

        if (event.getTime() >= end_date_now.getTime()) {
            handleEndTime(event, "start_time");
        }

        // handleDateTimePicker(event,schedule,setSchedule,setScheduleLocale);
        handleDateTimePicker(event, "start_time");
    }

    const handleEndTime = (event, mode = "handler") => {

        let start_date_now = {};
        let end_date_now = {};

        if (mode == "handler") {
            start_date_now = new Date(props.state.start_time_picker);
            end_date_now = new Date(event);
        } else if (mode == "start_time") {
            start_date_now = new Date(event);
            end_date_now = new Date(props.state.end_time_picker);
        }

        // console.log("end_date_now",end_date_now,start_date_locale,event.getTime())
        if (end_date_now.getTime() < start_date_now.getTime()) {
            end_date_now.setHours(start_date_now.getHours(), start_date_now.getMinutes() + 1, 0, 0);
            mode == "handler" ? props.setFormErrors(correct_end_time) : null;
        }
        // console.log("end_date_noww",end_date_now,start_date_locale,event.getTime())

        handleDateTimePicker(end_date_now, "end_time");
    }

    const customHooks = {speakers,coordinators,handleEndTime,handleStartTime,onSpeakersChange,onSyntonistesChange,handleSyntonistesOrder,handleSpeakersOrder,onInputchange,handleDescription}

    return {

        props:props,
        custom:customHooks,
    };

}

export const fields = [["title","text","12","Title"]
    ,["subtitle","editor","12","Description"]
    ,["start_time","datetimer","6","Start Date"]
    ,["end_time","datetimer","6","End Date"]
    ,["speakers","autocomplete_listDrag","6","Speakers"]
    ,["syntonistes","autocomplete_listDrag","6","Coordinators"]
]

export default LoadedScheduleHook;