import React ,{useState} from 'react';
import * as constants from "./Constants";

export function InputTextHandler({state, setState ,lang}) {


    const postData = (path,data,setFormErrors) => {
        const loadItems = async () => {
            const response = await axios.post(
                constants.LocalUrl + '/data/' + path, data).then(response => {
                // console.log("response.data",response.data)
                if (response.data.status == 'error') {
                    setFormErrors(response.data.data.errors);
                } else if (response.data.status == 'ok') {
                    // console.log("response.data",response.data)
                    return response.data.data;
                }
            });

        }

        if(loadItems()){
            var dataa = loadItems();
            return dataa;
        }

    }

    const setImage = (file, key) => {
        setState((prevState) => ({
            ...prevState,
            [key]: file,
        }));
    }

    const handleTwoDimensionArray = (event, key) => {
        event.persist();
        // console.log('handleDraggableOrder',event,state);

        setState(
            prevState => ({
                // object that we want to update
                ...prevState,    // keep all other key-value pairs
                [key]: {
                    ...prevState[key],
                    [event.target.name]: event.target.value,
                }      // update the value of specific key

            }))
        // console.log('handleDraggableOrder',event,state);
    }

    const handleDraggableListItemInState = (event, values, key) => {

        setState(
            prevState => ({
                // object that we want to update
                ...prevState,    // keep all other key-value pairs
                [key]: {
                    ...prevState[key],
                    ['items']: values,
                }      // update the value of specific key

        }))


        let _allUsers = [];
        // console.log("handleDraggableListItemInState",event, values, key)
        values.map((user,index) => {
            _allUsers.push(user.id)
        })

        setState(
            prevState => ({
                // object that we want to update
                ...prevState,    // keep all other key-value pairs
                [key]: {
                    ...prevState[key],
                    ['selected_items']: _allUsers,
                }      // update the value of specific key

            }))


    }

    const handleDraggableOrder = (event, key) => {


        setState(
            prevState => ({
                // object that we want to update
                ...prevState,    // keep all other key-value pairs
                [key]: {
                    ...prevState[key],
                    ['items']: event,
                }      // update the value of specific key

            }))

        // const response = axios.post(
        //     constants.LocalUrl + "/data/saveStartPageSpeakersOrder",{data : {['items']:event}}).then(
        //     notify(t("Speaker_order_changed"))
        // );
    }

    const onTextchange = (event) => {
        event.persist();
        console.log("onTextchange",state)

        if(typeof state[event.target.name] === 'object'){
            // console.log('onTextchange',state,event.target.name,event.target.value)

            setState((prevState) => ({
                ...prevState,
                [event.target.name]:{
                    ...prevState[event.target.name],
                    [lang]:event.target.value,
                }
            }));

            // console.log('onTextchanged',state,event.target.value)

        }else{
            // console.log('nope')
            setState((prevState) => ({
                ...prevState,
                [event.target.name]: event.target.value,
            }));

        }

        // console.log("onTextchange",state);
    };

    const handleCustomValue = (data,key,value_key) => {
        setState((prevState) => ({
            ...prevState,
            [key]: data && value_key?data[value_key]:data?data:null,
        }));
    }

    const handleColor = (data,key) => {
        setState((prevState) => ({
            ...prevState,
            [key]: data.hex,
        }));
    }

    const handleCountry = (event, value) => {
        setState((prevState) => ({
            ...prevState,
            ['Country']: value.code,
        }));
    };

    const onTimezoneChange = (event, value) => {
        setState((prevState) => ({
            ...prevState,
            ['timezone']: value.area,
        }));
    };

    const handleCheckbox = (event) => {
        event.persist();

        setState((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.checked,
        }));
    }

    const onRolesChange = (event, values) => {
        handleDraggableListItemInState(event, values,'roles')
    }

    const handleEditor = (data, key) => {
        // console.log("handlerEditor",state, data, key)
        if(typeof state[key] === 'object') {

            setState((prevState) => ({
                ...prevState,
                [key]: {
                    ...prevState[key],
                    [lang]: data,
                }
            }));

        }else{

            setState((prevState) => ({
                ...prevState,
                [key]: data
            }));

        }


    }

    const handleDateTimePicker = (event, key) => {


        setState((prevState) => ({
            ...prevState,
            [key]: event.getTime(),
        }));
        setState((prevState) => ({
            ...prevState,
            [key + "_picker"]: event,
        }));

    }



    // const handleEndDateTimePicker = (event, data, setState) => {
    //
    //     let start_date_locale = new Date(data.start_time_picker);
    //     let end_date_now = new Date(event);
    //     console.log("end_date_now",end_date_now,start_date_locale,event.getTime())
    //     if(end_date_now.getTime() < start_date_locale.getTime()){
    //         end_date_now.setHours(start_date_locale.getHours(), start_date_locale.getMinutes() + 1, 0, 0);
    //     }
    //     console.log("end_date_noww",end_date_now,start_date_locale,event.getTime())
    //
    //     setState((prevState) => ({
    //         ...prevState,
    //         end_time: end_date_now.getTime(),
    //     }));
    //     setState((prevState) => ({
    //         ...prevState,
    //         end_time_picker: end_date_now,
    //     }));
    //
    // }
    //
    // const handleStartDateTimePicker = (event, data, setState) => {
    //
    //     let start_date_now = new Date(event);
    //     let end_date_now = new Date(data.end_time_picker);
    //
    //     console.log("start_date_now",start_date_now,end_date_now,end_date_now.getTime(),event.getTime())
    //
    //     if(event.getTime() >= end_date_now.getTime()){
    //         let new_end_date = start_date_now.setHours(start_date_now.getHours(), start_date_now.getMinutes() + 1, 0, 0);
    //         console.log("start_date_now_uuuu")
    //         setState((prevState) => ({
    //             ...prevState,
    //             end_time_picker: new_end_date,
    //         }));
    //
    //         setState((prevState) => ({
    //             ...prevState,
    //             end_time: new_end_date,
    //         }));
    //
    //     }
    //
    //
    //     setState((prevState) => ({
    //         ...prevState,
    //         start_time: event.getTime(),
    //     }));
    //
    //     setState((prevState) => ({
    //         ...prevState,
    //         start_time_picker: event,
    //     }));
    //
    // }

    return {handleCustomValue,handleCheckbox,handleColor,handleDateTimePicker,postData,onTextchange,handleDraggableListItemInState,handleDraggableOrder,handleEditor,handleTwoDimensionArray,setImage};
};
