import React, {Suspense, useEffect} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ReactDOM from 'react-dom';
// import Login from "./Login/Login";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import ControlPanel from "./Panel";
import SpeakersIndividual from "./Page/content/SpeakersIndividual";
import Layout from "./Page/Layout";
import HomepageLayout from "./Page/HomepageLayout";
import Profile from "./Page/content/Profile";
import StageLayout from "./Page/content/Stage";
import SpeakerProfile from "./Page/content/SpeakerProfile";
import Agenda from "./Page/content/Agenda";
import SponsorsIndividual from "./Page/content/SponsorsIndividual";
import SoloSponsor from "./Page/content/SoloSponsor";
import RememberPassword from "./RememberPassword";
import ResetPassword from "./RememberPassword/ResetPassword";
import UserFields from "./Panel/common/UserFields";
import DraggableList from "./common/DraggableList";
import VideoCallNotifier from "./common/VideoCallNotifier";
import {TooltipProvider} from "../Providers/TooltipProvider"
// import Login from "./Page/content/Login";
import Login from "./Login/Login";
import Register from "./Register";
import Wizard from "./Wizard";
import {PageProvider} from "./Page/PageContext";
import {WizardProvider} from "./Wizard/WizardContext";
import Page from "./Page/content/Page";
// import Css from "./css";
import {createStore} from 'redux';
import * as constants from "./Panel/common/Constants"
import {ToastContainer} from "react-toastify";
import List from "./List";
import "../locale/i18n";
import {createTheme} from '@material-ui/core/styles';
import {enUS as coreENUS} from '@material-ui/core/locale';
import RightSidebar from "./common/RightSidebar";
import {ReactSVG} from "react-svg";
import CookieConsent from "./CookieConsent";
import Onboarding from "./Register/Onboarding";
import Loader from "./common/Loader";
import StageMobile from "./Page/content/Stage/StageMobile";
import {createMedia} from "@artsy/fresnel";
import LiveonChatWindows from "./common/LiveonChat/LiveonChatWindows";
import {ChatProvider} from "./Page/ChatContext";
import {ToastProvider} from "../Providers/ToastProvider";
import {useTranslation} from "react-i18next";
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
// import UpdateTemplate from "./CRUD/template";
// import Dynamic from "./Dynamic";

// const store = createStore(() => ({
//
//   }));
//
//
// const theme = createTheme(
//     {
//         palette: {
//             primary: { main: '#1976d2' },
//         },
//     },
//     coreENUS,
// );WIZ

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})

const App = () => {
    console.log("as!!");
    const {t, i18n} = useTranslation();

    useEffect(() => {
        //(process.env.NODE_ENV === "production" || process.env.REACT_APP_ENV === "STAGING") && GlobalDebug(false);
    }, []);


    return (
        <React.Fragment>

        <WizardProvider>
        <TooltipProvider>
        <PageProvider>
        <BrowserRouter>
        <ToastProvider>
            <Routes>
                {/*<Route path={constants.LocalUrl + '/app/login'} element={<UserForm/>} />*/}
                {/*<Route path={constants.LocalUrl + '/app/dynamic'} element={<Dynamic/>} />*/}
                {/*<Route path={constants.LocalUrl + '/app/testt'} element={<UpdateTemplate/>} />*/}
                {/*<Route path={constants.LocalUrl + '/app/list'} element={<List/>} />*/}
                <Route path={constants.LocalUrl + '/app/panel'} element={<ControlPanel/>} />
                <Route path={constants.LocalUrl + '/app/fields'} element={<UserFields/>} />
                <Route path={constants.LocalUrl + '/app/drag'} element={<DraggableList/>} />
            </Routes>


            <Routes>
                <Route exact path={constants.LocalUrl + '/install'} props="" element={<Wizard/>} />
            </Routes>


            <Layout>
                {/*<UsersImport style={{marginLeft:"15px"}}></UsersImport>*/}
                <Routes>

                    <Route path={constants.LocalUrl + '/'} pid="home"  element={<HomepageLayout/>} />
                    <Route path={constants.LocalUrl + '/speakers'} props="" element={<SpeakersIndividual/>} />
                    <Route path={constants.LocalUrl + '/profile/:user_id'} props="" element={<SpeakerProfile/>} />
                    <Route path={constants.LocalUrl + '/page/:page_id'} element={<Page/>} />

                    <Route path={constants.LocalUrl + '/sponsors/:id'} props="" element={<SoloSponsor/>} />
                    <Route path={constants.LocalUrl + '/sponsors'} props="" element={<SponsorsIndividual/>} />
                    <Route path={constants.LocalUrl + '/app/login'} element={<Login/>} />
                    <Route path={constants.LocalUrl + '/app/remember_password'} element={<RememberPassword/>} />
                    <Route path={constants.LocalUrl + '/app/reset_password'} element={<ResetPassword/>} />
                    <Route path={constants.LocalUrl + '/app/register'} element={<Register/>} />
                    <Route path={constants.LocalUrl + '/app/onboarding'} element={<Onboarding/>} />
                    <Route path={constants.LocalUrl + '/agenda'} props="" element={<Agenda/>} />
                    <Route element={<ProtectedRoute/>}>
                        <Route path={constants.LocalUrl + '/profile'} props="" element={<Profile/>} />

                        <Route path={constants.LocalUrl + '/stage'} props="" element={
                            <MediaContextProvider>
                                <Media at='mobile'>
                                    <StageMobile />
                                </Media>
                                <Media at='computer'>
                                    <div />
                                </Media>
                            </MediaContextProvider>
                        } />
                    </Route>

                </Routes>

                <MediaContextProvider>
                    <Media at='computer'>
                        <StageLayout position='main'/>
                    </Media>
                </MediaContextProvider>



                <audio id="VideoCallNotification" src="/notice.ogg"></audio>
                <audio id="chatNotification" src="/notice.ogg"></audio>
                {temp_user_id !=0?<VideoCallNotifier/>:""}
                {/*{temp_user_id !=0?<VideoWindow/>:""}*/}
            </Layout>



            <ChatProvider>
                {temp_user_id !=0?<RightSidebar drawerData={[
                    /* names must be unique */
                    {
                        name: 'my_chats',
                        label: t('my_chats'),
                        icon: <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-class-name');}} src={constants.LocalUrl + "/images/panel/sidebar_chat.svg"}/>,
                        // content: <LiveonChat/>,
                        accessKey: 'c',
                        roles: ['Attendee']
                    },
                    {
                        name: 'eventmanager',
                        label: t('eventmanager'),
                        icon: <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-class-name'); svg.setAttribute('style', 'width:27px');}} src={constants.LocalUrl + "/images/menu_stage.svg"} />,
                        // content: <EventManager/>,
                        accessKey: 'e',
                        roles: ['root', 'Administrator']
                    },
                    {
                        name: 'supporthelp',
                        label: t('supporthelp'),
                        icon: <ReactSVG beforeInjection={(svg) => {svg.classList.add('svg-class-name'); svg.setAttribute('style', 'width:27px');}} src={constants.LocalUrl + "/images/menu_support.svg"} />,
                        // content: <EventManager/>,
                        accessKey: 's',
                        roles: ['root', 'Administrator']
                    }
                ]}/>:null}
                <LiveonChatWindows open={true}/>
            </ChatProvider>

        </ToastProvider>
        </BrowserRouter>
        </PageProvider>
        </TooltipProvider>
        </WizardProvider>
            <ToastContainer
                position="top-right"
                autoClose={8000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <CookieConsent></CookieConsent>
    </React.Fragment>

    );
  }

export default App;

if (document.getElementById('app')) {
    ReactDOM.render(
    <React.Fragment>
        {/*<Provider store={store}>*/}
            <Suspense fallback={<div><Loader/></div>}>
        {/*<I18nextProvider i18n={i18next}>*/}
        {/*<ThemeProvider theme={theme}>*/}
            <App />
        {/*</ThemeProvider>*/}
        {/*</I18nextProvider>*/}
            </Suspense>
        {/*</Provider>*/}
    </React.Fragment>
    , document.getElementById('app'));
}
