import React, {createRef, useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import NewInvitation from "./components/NewInvitation";
import InvitationCreateButton from "./components/InvitationCreateButton";
import TableInvitations from "./components/TableInvitations";
import LoadedInvitation from "./components/LoadedInvitation";
import DrawerWindow from "../../common/DrawerWindow";
import CircularProgress from '@material-ui/core/CircularProgress';
import * as constants from "../../common/Constants";
import CTabsAutoScroll from "../../../common/CTabsAutoScroll";
import NewSendInvitation from "./components/NewSendInvitation";
import TableSentInvitations from "./components/TableSentInvitations";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InvitationPreview from "./components/InvitationPreview";
import Grid from "@material-ui/core/Grid";
import ViewSentLog from "./components/ViewSentLog";
import {useTranslation} from "react-i18next";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import TablePredefinedEmails from "./components/TablePredefinedEmails";
import Loader from "../../../common/Loader";
import {Typography} from "@material-ui/core";

export default function Invitations() {
    const {t, i18n} = useTranslation();
    const tabsRef = createRef();

    const [drawerstate, setDrawerstate] = useState({
            open: false,
            id: null
        }
    );
    const [drawerstateSend, setDrawerstateSend] = useState({
            open: false,
            row: null
        }
    );
    const [drawerstateViewLog, setDrawerstateViewLog] = useState({
            open: false,
            row: null
        }
    );
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [previewData, setPreviewData] = useState(false);

    const loadPost = async () => {

        // Till the data is fetch using API
        // the Loading page will show.
        setLoading(true);

        // Await make wait until that
        // promise settles and return its reult
        const response = await axios.post(
            constants.LocalUrl + "/data/get_all_invitations");

        // After fetching data stored it in posts state.
        setPosts(response.data);

        // Closed the loading page
        setLoading(false);
    }

    useEffect(() => {
        // Call the function
        let isMounted = true;
        if(isMounted){
            loadPost();
        }
        return () => {isMounted = false;}

    }, []);

    const toggleChildMenu = (attached, sponsor_id) => () => {
        setShowPreview(false);
        setDrawerstate({...drawerstate, open: attached, id: sponsor_id});
    };

    const toggleChildMenuSend = (attached, row) => () => {
        setDrawerstateSend({...drawerstateSend, open: attached, row: row});
        setShowPreview(false);
    };

    const toggleChildMenuViewLog = (attached, row, title) => () => {
        setDrawerstateViewLog({...drawerstateViewLog, open: attached, row: row, title: title});
        setShowPreview(false);
    };

    const toggleChildMenuFunc = (attached, sponsor_id) => {
        setDrawerstate({...drawerstate, open: attached, id: sponsor_id});
        setShowPreview(false);
    };

    const newPostsHandler = (data) => {
        setDrawerstate({...drawerstate, open: false, id: null});
        setDrawerstateSend({...drawerstateSend, open: false, row: null});
        setDrawerstateViewLog({...drawerstateViewLog, open: false, row: null});
        setShowPreview(false);
        setPreviewData(false);
        loadPost();
    };

    const updatePostsHandler = (data) => {
        setDrawerstate({...drawerstate, open: false, id: null});
        setDrawerstateSend({...drawerstateSend, open: false, row: null});
        setDrawerstateViewLog({...drawerstateViewLog, open: false, row: null});
        setShowPreview(false);
        setPreviewData(false);
        loadPost();
    };

    if (loading === true) {
        return <Loader/>
    }

    let layout_content;

    if (posts.length == 0) {
        // console.log("InvitationCreateButton");
        layout_content = <div>
            <Typography variant="h6" style={{textAlign: 'left', marginBottom: "15px", marginLeft: "20px"}}
                        gutterBottom>
                {t("Marketing_campaigns")}:
            </Typography>
            <InvitationCreateButton onClick={toggleChildMenu(true)}/>
            <br/>
            <br/>
            <Typography variant="h6" style={{textAlign: 'left', marginBottom: "15px", marginLeft: "20px"}}
                        gutterBottom>
                {t("Predefined_emails")}:
            </Typography>
            <TablePredefinedEmails email_list='predefined_emails'></TablePredefinedEmails>
        </div>;
    }

    if (posts !== null && posts.length > 0) {
        layout_content = <div>
                <InvitationCreateButton type={'small'} onClick={toggleChildMenu(true)}/>
                <br/>
                <br/>
                <Typography variant="h6" style={{textAlign: 'left', marginBottom: "15px", marginLeft: "20px"}}
                            gutterBottom>
                    {t("Marketing_campaigns")}:
                </Typography>
                <TableInvitations props={posts} triggerdrawer={toggleChildMenu} triggerdrawerSend={toggleChildMenuSend} triggerdrawerfunc={toggleChildMenuFunc} triggerdataload={updatePostsHandler}/>
                <br/>
                <br/>

                <Typography variant="h6" style={{textAlign: 'left', marginBottom: "15px", marginLeft: "20px"}}
                            gutterBottom>
                    {t("Predefined_emails")}:
                </Typography>
                <TablePredefinedEmails email_list='predefined_emails' ></TablePredefinedEmails>
            </div>;
    }


    const tabs = {
        titles: [t('PREPARE EMAILS'), t('BATCH SEND LOG')],
        content: [
            <div>
                <br/>
                {layout_content}
                <DrawerWindow open={drawerstate.open}>
                    <div style={{width: '80vh' ,display:'flex',justifyContent:'center'}}>
                        <Grid container spacing={2} style={{padding:'0px',maxWidth:'100%'}}>
                            {/*{*/}
                            {/*    showPreview*/}
                            {/*        ? <Grid item xs={6}>*/}
                            {/*            <InvitationPreview previewData={previewData}></InvitationPreview>*/}
                            {/*        </Grid>*/}
                            {/*        : <></>*/}
                            {/*}*/}
                            <Grid item xs={12}>
                                {
                                    drawerstate.id ?
                                        <LoadedInvitation props={drawerstate.id} posts={posts} triggerdrawer={toggleChildMenu}
                                                          triggerdrawerfunc={toggleChildMenuFunc}
                                                          triggerdataload={updatePostsHandler} /> :
                                        <NewInvitation triggerdrawer={toggleChildMenu} triggerdrawerfunc={toggleChildMenuFunc}
                                                       triggerdataload={newPostsHandler}/>
                                }
                            </Grid>
                        </Grid>
                    </div>
                </DrawerWindow>
                <DrawerWindow open={drawerstateSend.open}>
                    <div style={{width: '80vh', display:'flex', justifyContent:'center'}}>
                        <Grid container spacing={2} style={{padding:'0px',maxWidth:'100%'}}>
                            {/*<Grid item xs={6}>*/}
                            {/*    {*/}
                            {/*        drawerstateSend.row*/}
                            {/*            ? <InvitationPreview previewData={drawerstateSend.row}></InvitationPreview>*/}
                            {/*            : <></>*/}
                            {/*    }*/}

                            {/*</Grid>*/}
                            <Grid item xs={12}>
                                {
                                    drawerstateSend.row ?
                                        <NewSendInvitation invitation={drawerstateSend.row} triggerdrawer={toggleChildMenuSend}
                                                           triggerdrawerfunc={toggleChildMenuSend}
                                                           triggerdataload={updatePostsHandler}></NewSendInvitation>
                                        :
                                        <></>
                                }
                            </Grid>
                        </Grid>
                    </div>
                </DrawerWindow>
            </div>,
            <div>
                <TableSentInvitations triggerdrawer={toggleChildMenuViewLog}/>
                <DrawerWindow open={drawerstateViewLog.open}>
                    <div style={{width: '80vh', maxWidth: '100%', display:'flex', justifyContent:'center'}}>
                        <ViewSentLog batch={drawerstateViewLog.row} campaing={drawerstateViewLog.title} triggerdrawer={toggleChildMenuViewLog}
                                           triggerdrawerfunc={toggleChildMenuViewLog}></ViewSentLog>
                    </div>
                </DrawerWindow>
            </div>
        ]
    };


    return (
        <SimpleBar forceVisible="y" autoHide={false} style={{maxHeight:"658px", height:"658px", overflowX: "hidden"}} >
            <div style={{padding: '1px', width: '100%', margin: 0}}>
                <div style={{paddingRight:'10px'}}>
                <CTabsAutoScroll ref={tabsRef} titles={tabs.titles} contents={tabs.content}></CTabsAutoScroll>
                </div>
            </div>
        </SimpleBar>
    );

}


