import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CTextField from "../../../common/CTextField";
import Editor from "../../../common/Editor";
import {useTranslation} from "react-i18next";
import PanelBottomBar from "../../../../common/PanelBottomBar";
import AutoCompleteField from "../../../../common/AutoCompleteField";
import LoadedScheduleHook from "./LoadedScheduleHook";
import DateTimer from "../../../../common/DateTimer";
import UpdateHook from "../../updateHook";



export default function NewSchedule(props){

    const {t, i18n} = useTranslation();

    const hooks = UpdateHook(LoadedScheduleHook,'agenda')(props);

    let schedule = hooks.props.state


    return (
        <div className="bottom_bar">

            <Typography variant="h3" style={{textAlign:'center'}} gutterBottom>
                {t('New Session')}
            </Typography>
            <Divider style={{backgroundColor:"#39BB0F",marginBottom:"15px"}}/>


            <div style={{display:'flex',justifyContent:'center'}}>
                <Grid container spacing={4} style={{padding:'20px',maxWidth:'800px'}}>

                    <Grid item xs={12}>
                        <Typography xs={12} variant="h3" style={{textAlign:'left'}} gutterBottom>
                            {t('Session Details')}
                        </Typography>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <CTextField handler={hooks.custom.onInputchange} name='title' label={t('Title')} required error={hooks.props.hasError('title')} helperText={hooks.props.errorMessage('title')}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Editor handler={hooks.custom.handleDescription} label={t("Description")} name="subtitle"/>
                    </Grid>

                    <Grid item xs={6}>
                        <DateTimer
                            label={t('Start Date')}
                            data={schedule.start_time_picker}
                            handler={hooks.custom.handleStartTime}
                            name="start_time"
                            hasError={hooks.props.hasError("start_time")}
                            errorMessage={hooks.props.errorMessage("start_time")}
                            required
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DateTimer
                            label={t('End Date')}
                            data={schedule.end_time_picker}
                            handler={hooks.custom.handleEndTime}
                            name="end_time"
                            hasError={hooks.props.hasError("end_time")}
                            errorMessage={hooks.props.errorMessage("end_time")}
                            required
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography xs={12} variant="h3" style={{textAlign:'left'}} gutterBottom>
                            {t('Speakers & Coordinators')}
                        </Typography>
                        <Divider />
                    </Grid>

                    <Grid item xs={6}>
                        <AutoCompleteField
                            label={t('Speakers')}
                            placeholder={t("Choose a speaker...")}
                            model={hooks.custom.speakers}
                            data={schedule.speakers}
                            onChange={hooks.custom.onSpeakersChange}
                            handleItemsOrder={hooks.custom.handleSpeakersOrder}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <AutoCompleteField
                            label={t('Coordinators')}
                            placeholder={t("Choose a coordinator...")}
                            model={hooks.custom.coordinators}
                            data={schedule.syntonistes}
                            onChange={hooks.custom.onSyntonistesChange}
                            handleItemsOrder={hooks.custom.handleSyntonistesOrder}
                        />
                    </Grid>

                    <PanelBottomBar classes={"panel_utilities_element basicinfo"} close={hooks.props.triggerdrawer(false)} style={{justifyContent:"space-between"}} submit={hooks.props.onSubmit} />


                </Grid>
            </div>
        </div>
    )

}