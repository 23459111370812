import React, {useContext, useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import DraggableSimplestList from "./DraggableSimplestList";
import Paper from "@material-ui/core/Paper";
import {useTranslation} from "react-i18next";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {makeStyles} from "@material-ui/core/styles";
import Loader from "./Loader";
import PageProvider from "../Page/PageContext";

const usedStyles = makeStyles({
    root: {
        color: "#39BB0F",
        "&$checked": {
            color: "#39BB0F"
        }
    }
});

const new_schedule = makeStyles((theme) => ({
    root: {
        display: 'block',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(45),
            // height: theme.spacing(13),
        },
    },
    new_schedule: {
        // width:'100%',
        textAlign: 'center'
    }
}));

export default function
    AutoCompleteField(props){

    const {t, i18n} = useTranslation();
    const checkboxes_style = usedStyles();
    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small" className={checkboxes_style.root}/>;
    const checkedIcon_big = <CheckBoxIcon className={checkboxes_style.root}/>;
    const [checked, setChecked] = useState(false);


    const return_options = (selected_data) => {
        let arr = [];
        console.log("return_options",props.model,arr,selected_data);
        props.model && props.model.map((e,index) =>{
            if(selected_data && selected_data.includes(e.id)){
                arr.push(props.model[index]);
            }
        });
        // console.log("return_options",props.data,arr,selected_data);
        return arr;
    }
    let single_style = "draggable_autocomplete_wrapper single";
    let with_sorting_style = "draggable_autocomplete_wrapper";



    return(
        props.type == "single"?<Paper className="draggable_autocomplete_wrapper single" elevation={0} style={{
                padding: 8,display:'inline-block',marginRight: '10px',width:'100%'}}>
                <Typography variant="h6" style={{display: 'block',margin:"0 20px 17px"}} gutterBottom>
                    {props.label}
                </Typography>
            {props.selectAll?
                <>
                    <Typography className={new_schedule} variant="h7" gutterBottom
                                style={{textAlign: 'left', marginBottom: "15px", marginLeft: "20px"}}>{t('Select_all_users')}</Typography>
                    <Checkbox
                        checked={checked}
                        checkedIcon={checkedIcon_big}
                        onChange={(event) => {
                            props.selectAll(event);
                            setChecked(!checked);
                        }}
                        // color="#000"
                    />
            </>:null}
                <div id="autocomplete_selector">

                    <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        popupIcon={<ExpandMoreIcon />}
                        options={props.model?props.model:null}
                        disableCloseOnSelect
                        onChange={props.onChange}

                        // defaultValue={return_options(props.data && props.data.selected_items?props.data.selected_items:null)}
                        value={return_options(props.data && props.data.selected_items?props.data.selected_items:null)}
                        getOptionLabel={(option) => option.title}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                    // color="#000"
                                />
                                {option.title}
                            </React.Fragment>
                        )}
                        style={{ }}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" label=""  placeholder={props.placeholder}/>
                        )}
                    />
                </div>

            </Paper>:<Paper className="draggable_autocomplete_wrapper" elevation={0} style={{
                padding: 8,display:'inline-block',marginRight: '10px',width:'100%'}}>
                <Typography variant="h6" style={{display: 'block',margin:"11px 20px 17px"}} gutterBottom>
                    {props.label}
                </Typography>
                <div id="autocomplete_selector">
                    <Autocomplete
                        multiple
                        popupIcon={<ExpandMoreIcon />}
                        id="checkboxes-tags-demo"
                        options={props.model?props.model:null}
                        disableCloseOnSelect
                        onChange={props.onChange}
                        value={return_options(props.data && props.data.selected_items?props.data.selected_items:null)}
                        getOptionLabel={(option) => option.title}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                    // color="#000"
                                />
                                {option.title}
                            </React.Fragment>
                        )}
                        style={{ }}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" label=""  placeholder={props.placeholder}/>
                        )}
                    />

                    {props.data && props.data.items && Object.keys(props.data.items).length > 0?<h3 className="autocomplete_sorting_title">{t("Sorting")}</h3>:null}
                    <div>
                        <DraggableSimplestList  data={props.data && props.data.items?props.data.items:null} handleItemsOrder={props.handleItemsOrder?props.handleItemsOrder:null} save={props.save?props.save:null}/>
                    </div>
                    {/*:null}*/}
                </div>
            </Paper>


    )


}