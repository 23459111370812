import request from '../../../utils/request'

function get(id,type) {
    return request({
        url:    `/data/schedule_full_data`,
        method: 'POST',
        data: {id:id,type:type}
    });
}



function create(data) {
    return request({
        url:    '/data/create_schedule',
        method: 'POST',
        data:   data
    });
}

function update(data, lang) {
    return request({
        url:    '/data/update_schedule',
        method: 'POST',
        data: {...data,lang:lang}

    });
}

const MessageService = {
    get, create, update //, update, delete, etc. ...
}

export default MessageService;