import React, {useContext, useEffect, useState} from 'react';
import {List, ListItem, ListItemText} from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as constants from "../../common/Constants";
import FileUploadComponent from "../../common/FileUploadComponent";
import {toast} from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useTranslation} from 'react-i18next';
import DateFnsUtils from "@date-io/date-fns";

import {DatePicker, DateTimePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import BasicInfoService from "../../../../api/services/Panel/basicInfo";
import CTextField from "../../common/CTextField";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import LanguageChangerForPanel from "../../../common/Language/LanguageChangerForPanel";
import Loader from "../../../common/Loader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PanelBottomBar from "../../../common/PanelBottomBar";
import PageProvider from "../../../Page/PageContext";
import {
  datepickerLocales,
  datepickerCancelLabelLocales,
  datepickerClearLabelLocales
} from "../../../common/Language/DatepickerLocales.js"


export default function BasicInfo () {

  const {setReloadData} = useContext(PageProvider);
  const {t, i18n} = useTranslation();

  const notify = () => toast.success(t('Succesfully saved!'), {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });


  const [state,setState] =  useState({});
  const [timezoneInputValue,setTimezoneInputValue] =  useState({});
  const [zones,setZones] =  useState([]);
  const [settings,setSettings] =  useState([]);
  const [loading,setLoading] =  useState([]);
  const [localeLanguage, setLocaleLanguage] = useState(i18n.language);

  let timezone_formats = {
    1 : "24hr",
    2 : "12hr",
  };

  let date_formats = {
    1 : "day/month/year",
    2 : "month/day/year",
    3 : "year/month/day",
  };

  const setLanguage = (value) =>{
    BasicInfoService.get(value).then((response) => {
      let s_time = new Date(response.EVENT_TIME * 1000)
      response.start_time_picker = state.start_time_picker;
      console.log('response',response)
      setState(response);
      setLocaleLanguage(value)
    });
  }

  const handleStartDate = (event) => {

    // let date = new Date(state.EVENT_DATE);
    // date.setHours(event.getHours(), event.getMinutes(), 0, 0);


    setState((prevState) => ({
      ...prevState,
      EVENT_DATE: event.getTime(),
    }));

    setState((prevState) => ({
      ...prevState,
      start_date_picker: event,
    }));
    // console.log("handleStartDate",state)
  }

  const handleStartTime = (event) => {

    let date = new Date(state.EVENT_DATE);
    date.setHours(event.getHours(), event.getMinutes(), 0, 0);

    // console.log(event,event.getTime(),date.getTime())
    setState((prevState) => ({
      ...prevState,
      EVENT_TIME: event.getTime(),
    }));

    setState((prevState) => ({
      ...prevState,
      start_time_picker: event,
    }));

  }

  const onInputchange = (event) => {

    event.persist();
    setState((prevState) => ({
            ...prevState,    // keep all other key-value pairs
            [event.target.name]: event.target.value       // update the value of specific key

        }))

  }

  const onTimezoneChange = (event, newValue) => {

    setState((prevState) => ({
      ...prevState,    // keep all other key-value pairs
      ['timezone']: newValue ? newValue.area : '',
    }))

  };

  const onSubmitForm = () => {

    window.axios.post(constants.LocalUrl + '/data/save_basic_info',{form:state,lang:localeLanguage}).then((response) => {
      if(response.data.status == "ok"){
        setReloadData(true);
        notify();
      }
    })

  }

  useEffect(() => {

    if(zones.length == 0){

      BasicInfoService.timezones().then((response) => {
        setZones(response);
      });

      BasicInfoService.get().then((response) => {
        // console.log(response,"lll");
        let s_time = new Date(response.EVENT_TIME * 1000)
        // let s_date = new Date(response.EVENT_DATE)
        // console.log("s_date",s_date);
        response.start_time_picker = s_time;
        setState(response);
      }).catch();


       window.axios.post(constants.LocalUrl + '/data/get_settings').then(response => {

        // After fetching data stored it in posts state.
        // console.log("////");
        // console.log(response.data);

        setSettings(response.data);

      });
    }

  },[])



    if(zones.length == 0 || Object.values(settings).length === 0 || Object.values(state).length === 0){
      return <Loader />;
    }


    return (
        <SimpleBar forceVisible="y" autoHide={false} className="bottom_bar" style={{maxHeight:"658px", height:"658px"}}>
          <Grid container spacing={4} style={{width: '100%', margin: '0 0 0 -15px'}}>

            <Grid item xs={12} className="logo_images" >
                <fieldset disabled={settings.EVENT_STARTED == 1}>
                  <Typography variant="h6" style={{display: 'block',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                    {t('Event logo')}
                    <span className="image_text_description">{t('Maximum')} {t('upload_dimensions', {text: '400px * 130px'})}</span>
                  </Typography>
                  <FileUploadComponent id="1" image={settings && settings.logo?settings.logo:''} extra={{action:"/data/addSettingsLogo"}}
                       accept={constants.acceptedUploadMimeGroups.images} maxSizeBytes={constants.acceptedUploadFileSizesInBytes.images}
                       maxWidth={400} maxHeight={130}
                  />
                </fieldset>
            </Grid>


            <Grid item xs={6}>
                <fieldset disabled={settings.EVENT_STARTED == 1}>
                    <CTextField  handler={onInputchange} name='EVENT_NAME' label={t('Event name')} value={state.EVENT_NAME}/>
                </fieldset>
            </Grid>

            <Grid item xs={6}>
                <fieldset disabled={settings.EVENT_STARTED == 1}>
                    <Typography variant="h6"  style={{display: 'block',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                      {t('Event date')}
                      </Typography>
                      <MuiPickersUtilsProvider style={{width:"100%"}} utils={DateFnsUtils} locale={datepickerLocales[i18n.language]}>
                        <DatePicker
                            id="outlined-textarea"
                            inputVariant="outlined"
                            name="EVENT_DATE"
                            ampm={true}
                            label=""
                            value={state.start_date_picker?state.start_date_picker:state.EVENT_DATE}
                            required
                            // fullwidth
                            // error={hasError('start_date')}
                            // helperText={errorMessage('start_date')}
                            // minDate={new Date()}
                            // maxDate={new Date(settings.EVENT_DATE_TO)}
                            onChange={handleStartDate}
                            onError={console.log}
                            format={state.EVENT_DATE_FORMAT_UI}
                            cancelLabel={datepickerCancelLabelLocales[i18n.language]}
                            clearLabel={datepickerClearLabelLocales[i18n.language]}
                            style={{width:"100%"}}
                        />
                      </MuiPickersUtilsProvider>
                      {/*<TextField onChange={onInputchange} style={{borderRadius: '23px'}} id="outlined-basic"  variant="outlined" format="dd/mm/yyyy" defaultValue="24/05/2017"  type="date" fullWidth InputLabelProps={{ shrink: state.EVENT_DATE?true:false }} name="EVENT_DATE" value={state.EVENT_DATE}/>*/}
                </fieldset>
            </Grid>

            <Grid item xs={6}>
                <fieldset disabled={settings.EVENT_STARTED == 1}>
                    <Typography variant="h6" style={{display: 'block',marginBottom:"15px",marginLeft:"20px"}}  gutterBottom>
                      {t('Event time')}
                      </Typography>
                      {/*<TextField onChange={onInputchange} style={{borderRadius: '23px'}} id="outlined-basic" defaultValue="07:30"  type="time"  variant="outlined" fullWidth InputLabelProps={{ shrink: state.EVENT_TIME?true:false }} name="EVENT_TIME" value={state.EVENT_TIME}/>*/}
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={datepickerLocales[i18n.language]}>
                        <TimePicker
                            id="date-picker-dialog"
                            inputVariant="outlined"
                            clearable
                            ampm={state.TIME_FORMAT == 1?false:true}
                            label=""
                            value={state.start_time_picker?state.start_time_picker:new Date()}
                            onChange={handleStartTime}
                            variant="outlined"
                            cancelLabel={datepickerCancelLabelLocales[i18n.language]}
                            clearLabel={datepickerClearLabelLocales[i18n.language]}
                            style={{width:"100%"}}
                        />
                      </MuiPickersUtilsProvider>
                </fieldset>
            </Grid>

            <Grid item xs={6}>
                <fieldset disabled={settings.EVENT_STARTED == 1}>
                    <CTextField  handler={onInputchange} name='EVENT_DATE_STR' label={t('Event date (in text)')} value={state.EVENT_DATE_STR}/>
                </fieldset>
            </Grid>

            <Grid item xs={6}>
                <fieldset disabled={settings.EVENT_STARTED == 1}>
                    <CTextField  handler={onInputchange} name='EVENT_DAY' label={t('Event day')} value={state.EVENT_DAY}/>
                </fieldset>
            </Grid>

            <Grid item xs={6}>
                <fieldset disabled={settings.EVENT_STARTED == 1}>
                    <Typography variant="h6" style={{display: 'block',borderRadius:'23px',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                      {t('Timezone')}
                    </Typography>
                    <Autocomplete
                        id="grouped-native-select"
                        popupIcon={<ExpandMoreIcon />}
                        onChange={onTimezoneChange}
                        options={zones}
                        groupBy={(option) => option.zone}
                        getOptionLabel={(option) => option.area}
                        renderOption={(option) => (
                            <React.Fragment>
                              {option.area} {option.timezone}
                            </React.Fragment>
                        )}
                        defaultValue={zones.filter((item)=> item.area == state.timezone)[0]}
                        required={true}
                        renderInput={(params) => <TextField {...params} name='timezone' variant="outlined" style={{padding: 0}} />}
                    />
                </fieldset>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h6" style={{display: 'block',borderRadius:'23px',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                {t('TIME_FORMAT')}
              </Typography>
              <Select style={{width:"100%",borderRadius:'23px'}} onChange={onInputchange} id="grouped-native-select"  variant="outlined" value={state && state.TIME_FORMAT?state.TIME_FORMAT:""} name="TIME_FORMAT" IconComponent={ExpandMoreIcon}>
                    {Object.keys(timezone_formats).map(data => (
                        <option key={"ioi"+data} /*selected={state.timezone === data?'selected':''}*/ value={data}>{timezone_formats[data]}</option>
                    ))}
              </Select>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h6" style={{display: 'block',borderRadius:'23px',marginBottom:"15px",marginLeft:"20px"}} gutterBottom>
                {t('DATE_FORMAT')}
              </Typography>
              <Select style={{width:"100%",borderRadius:'23px'}} onChange={onInputchange} id="grouped-native-select"  variant="outlined" value={state && state.DATE_FORMAT?state.DATE_FORMAT:""} name="DATE_FORMAT" IconComponent={ExpandMoreIcon}>
                {Object.keys(date_formats).map(data => (
                    <MenuItem key={"dda"+data} value={data}>{date_formats[data]}</MenuItem>
                ))}
              </Select>
            </Grid>

            <PanelBottomBar classes={"panel_utilities_element basicinfo"} style={{justifyContent:"flex-end"}} submit={onSubmitForm} language={setLanguage}/>
            </Grid>
      </SimpleBar>
    );

}

// export default withTranslation()(BasicInfo);
