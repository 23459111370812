import React, {useContext} from 'react';
import "react-chat-elements/dist/main.css";
import PageProvider from "../Page/PageContext";
import {CircularProgress} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Loader from "./Loader";

export default function SupportHelp(props) {

    const {settings} = useContext(PageProvider);
    const {t, i18n} = useTranslation();

    if (settings == undefined || Object.keys(settings).length == 0) {
        return <Loader></Loader>
    }

    if (window.temp_user_id) {
        return (
            <div className={'eventmanager_sidebar'}>
                {
                    props.open
                        ? <>
                            <Typography xs={12} alignLeft variant="h5" style={{
                                textAlign: 'left',
                                fontWeight: 600,
                                display: "flex",
                                lineHeight: "20px",
                                justifyContent: "flex-start",
                                marginTop: '20px'
                            }} gutterBottom>
                                {t('supporthelp')}
                            </Typography>
                            <Divider bold style={{height: "3px", backgroundColor: "var(--divider-color) "}}/>
                            <br/>
                            <p style={{fontSize: '16px'}}>{t('supporthelp_text')}</p>
                            <div className="participants_body" style={{padding: 0}}>
                                <div className="speaker_profile_header" style={{marginBottom: "30px"}}>
                                    <div className="card_speaker">
                                        <div className="speaker_profile_content">
                                            <div className="speaker_img">
                                                {settings.assigned_cse.image ? <img src={settings.assigned_cse.image}
                                                                                    alt={settings.assigned_cse.name}/> : ""}
                                            </div>
                                            <div className="speaker_referrence">
                                                <div className="speaker_referrence_content">
                                                    <span className="name">{settings.assigned_cse.name ?? ""}</span>
                                                    <span className="job">{settings.assigned_cse.jobtitle ? settings.assigned_cse.jobtitle : ""} {settings.assigned_cse.company ? "@ " + settings.assigned_cse.company : ""}</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <ul>
                                    {settings.assigned_cse.license ? <li>{t("supporthelp_license")}: <span className="user_attribute">{settings.assigned_cse.license}</span></li> : ""}
                                    {settings.assigned_cse.email ? <li>{t("Email")}: <span className="user_attribute"><a href={'mailto:'+settings.assigned_cse.email}>{settings.assigned_cse.email}</a></span></li> : ""}
                                    {settings.assigned_cse.phone ? <li>{t("Phone")}: <span className="user_attribute"><a href={'tel:'+settings.assigned_cse.phone}>{settings.assigned_cse.phone}</a></span></li> : ""}
                                </ul>
                            </div>
                        </>
                        : null
                }
            </div>
        )
    } else return <CircularProgress/>;

}
