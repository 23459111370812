import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CTextField from "../../../common/CTextField";
import Editor from "../../../common/Editor";
import {useTranslation} from "react-i18next";
import Loader from "../../../../common/Loader";
import PanelBottomBar from "../../../../common/PanelBottomBar";
import AutoCompleteField from "../../../../common/AutoCompleteField";
import LoadedScheduleHook from "./LoadedScheduleHook";
import DateTimer from "../../../../common/DateTimer";
import UpdateHook from "../../updateHook"


export default function LoadedSchedule(props){


    const {t, i18n} = useTranslation();

    const hooks = UpdateHook(LoadedScheduleHook,'agenda')(props);

    let scheduleLocale = hooks.props.stateLocale
    let schedule = hooks.props.state


    // return handleEndTime;
    // if(Object.keys(schedule).length == 0
    //     // || Object.keys(speakers).length == 0 || Object.keys(coordinators).length == 0 || Object.keys(sponsors).length == 0
    //     // || sponsors.length == 0 || coordinators.length == 0 || speakers.length == 0
    // ){
    //     return <Loader />
    // }
    // return null;
    // console.log("hooks.custom.onInputchange",hooks)

    return (
        <div className="bottom_bar">

            <Typography variant="h3" style={{textAlign:'center'}} gutterBottom>
                {t('Edit Session')}
            </Typography>
            <Typography variant="subtitle1" style={{textAlign:'center'}} gutterBottom>
                <span style={{fontSize:"21px",fontStyle:"italic"}}>{scheduleLocale.title}</span>
            </Typography>
            <Divider style={{backgroundColor:"#39BB0F",marginBottom:"15px"}}/>
            <div style={{display:'flex',justifyContent:'center'}}>
            <Grid container spacing={4} style={{padding:'20px',maxWidth:'800px'}}>

            <Grid item xs={12}>
                <Typography xs={12} variant="h3" style={{textAlign:'left'}} gutterBottom>
                    {t('Session Details')}
                </Typography>
                <Divider light />
            </Grid>

            <Grid item xs={12}>
                <CTextField value={scheduleLocale && scheduleLocale.title?scheduleLocale.title:''} handler={hooks.custom.onInputchange} name='title' label={t('Title')} required error={hooks.props.hasError('title')} helperText={hooks.props.errorMessage('title')}/>
            </Grid>

            <Grid item xs={12}>
                <Editor lang={hooks.props.localeLanguage} handler={hooks.custom.handleDescription} value={hooks.props.stateLocale.subtitle} label={t("Description")}  name="subtitle"/>
            </Grid>

            <Grid item xs={6}>
                <DateTimer
                    label={t('Start Date')}
                    data={schedule.start_time_picker}
                    handler={hooks.custom.handleStartTime}
                    name="start_time"
                    hasError={hooks.props.hasError("start_time")}
                    errorMessage={hooks.props.errorMessage("start_time")}
                    required
                />
            </Grid>

            <Grid item xs={6}>
                <DateTimer
                    label={t('End Date')}
                    data={schedule.end_time_picker}
                    handler={hooks.custom.handleEndTime}
                    name="end_time"
                    hasError={hooks.props.hasError("end_time")}
                    errorMessage={hooks.props.errorMessage("end_time")}
                    required
                />
            </Grid>

            <Grid item xs={12}>
                <Typography xs={12} variant="h3" style={{textAlign:'left'}} gutterBottom>
                    {t('Speakers & Coordinators')}
                </Typography>
                <Divider light />
            </Grid>

            {Object.keys(hooks.custom.speakers).length > 0?<Grid item xs={6}>
                <AutoCompleteField
                    label={t('Speakers')}
                    placeholder={t("Choose a speaker...")}
                    model={hooks.custom.speakers}
                    data={schedule.speakers}
                    onChange={hooks.custom.onSpeakersChange}
                    handleItemsOrder={hooks.custom.handleSpeakersOrder}
                />

            </Grid>:null}

            {Object.keys(hooks.custom.coordinators).length > 0?<Grid item xs={6}>
                <AutoCompleteField
                label={t('Coordinators')}
                placeholder={t("Choose a speaker...")}
                model={hooks.custom.coordinators}
                data={schedule.syntonistes}
                onChange={hooks.custom.onSyntonistesChange}
                handleItemsOrder={hooks.custom.handleSyntonistesOrder}
            /></Grid>:null}

                {console.log("console.log",hooks)}

            <PanelBottomBar language={hooks.props.changeLocale} close={hooks.props.triggerdrawer(false)}  submit={hooks.props.onSubmit}/>


        </Grid>

        </div>
        </div>
    )
}